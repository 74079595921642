<template>
    <div class="index">
        <table-page-template
            v-bind="templateConfig"
            :paging-config.sync="getSchoolListParams"
            :total-count="totalCount"
            @page-change="getSchoolTableData"
        >
            <template #tableOperate="{ item }">
                <el-link type="primary" :underline="false" @click="assignPaper(item)">分配实训试卷</el-link>
                <el-link type="primary" :underline="false" @click="editSchool(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="deleteSchool(item)">删除</el-link>
            </template>
        </table-page-template>
        <my-dialog
            class="operate-school"
            :title="operateDialogTitle"
            :confirm-handler="confirmOperate"
            padding="40px 0 18px"
            :is-show.sync="operateDialog"
            @closeDialog="clearFormData"
        >
            <div class="form-box">
                <pack-el-form ref="elForm" :rules="rules" :params.sync="operateSchoolParams" :form-config="formConfig"></pack-el-form>
            </div>
        </my-dialog>
        <my-dialog
            class="assign-paper"
            title="分配实训试卷"
            padding="20px"
            :is-show.sync="assignDialog"
            :confirm-handler="confirmAssign"
            @closeDialog="clearAssignParams"
        >
            <div class="assign-content">
                <el-scrollbar style="height: 100%">
                    <div class="paper-list">
                        <div class="main" v-if="practicalTrainOptions.length > 0">
                            <div
                                class="paper-item"
                                v-for="item in practicalTrainOptions"
                                :key="item.id"
                                :class="{ 'assign-select': item.isSelect }"
                                @click="toggleSelectState(item, !item.isSelect)"
                            >
                                <el-checkbox v-model="item.isSelect" @click.native.stop></el-checkbox>
                                <div class="label" id="label">{{ item.name }}</div>
                            </div>
                        </div>
                        <div class="empty" v-else>暂无可选试卷</div>
                    </div>
                </el-scrollbar>
            </div>
        </my-dialog>
        <my-dialog class="delete-school" :is-show.sync="deleteDialog" title="删除学校" :confirm-handler="confirmDelete">
            <div class="content">是否删除该学校, 删除后将无法恢复</div>
        </my-dialog>
    </div>
</template>

<script>
import TablePageTemplate from "components/common/TablePageTemplate";
import PackElForm from "components/common/PackElForm";
import MyDialog from "components/common/MyDialog";
import {
    getSchoolListData,
    sendOperateSchool,
    sendDeleteSchool,
    sendSchoolAssignPaper,
    sendGetAlreadyAssignPaper,
    sendSchoolUploadLogo,
    getPracticalTrainData,
} from "@/utils/apis";
export default {
    name: "Index",
    data() {
        return {
            tableData: [],
            rules: {
                name: [
                    { required: true, message: "请输入学校名称", trigger: "blur" },
                    { min: 4, message: "学校名称的长度不能小于4", trigger: "blur" },
                ],
            },
            getSchoolListParams: {
                page: 1,
                limit: 10,
            },
            operateSchoolParams: {
                name: "",
                avatar: "",
                // id: -1,
            },
            assignParams: {
                school_id: "",
                paper_id: "",
            },
            totalCount: 1,
            operateDialogTitle: "",
            uploadImgPath: "school/upload",
            deleteId: -1,
            practicalTrainOptions: [],
            //dialog
            deleteDialog: false,
            operateDialog: false,
            assignDialog: false,
        };
    },
    components: {
        TablePageTemplate,
        MyDialog,
        PackElForm,
    },
    computed: {
        host() {
            let host = localStorage.getItem("hosturl");
            return !host ? location.origin : host;
        },
        templateConfig() {
            return {
                tableData: this.tableData,
                rightConfig: {
                    text: "创建学校",
                    handler: this.createSchool,
                },
                tableConfig: [
                    { label: "学校名称", prop: "name", width: 400 },
                    { label: "LOGO", prop: "avatar", isImg: true, align: "center" },
                    { label: "创建时间", prop: "create_time", align: "center" },
                    { label: "操作", width: 300, align: "center" },
                ],
            };
        },
        formConfig() {
            return [
                {
                    label: "学校名称",
                    prop: "name",
                    placeholder: "4-30个字符",
                    max: 30,
                },
                {
                    label: "LOGO",
                    prop: "avatar",
                    type: "img",
                    uploadPath: this.uploadImgPath,
                    clear: this.clearAvatar,
                    uploadSuccess: this.uploadSuccess,
                    uploadErr: this.uploadErr,
                },
            ];
        },
    },
    methods: {
        init() {
            this.getSchoolTableData();
            this.getPracticalTrainOptions();
        },
        async getSchoolTableData() {
            let res = await getSchoolListData(this.getSchoolListParams);
            this.totalCount = res.data.total;
            this.tableData = res.data.list;
        },
        async getPracticalTrainOptions() {
            const res = await getPracticalTrainData({ paging: 0 });
            this.practicalTrainOptions = res.data.list.map((item) => ({
                id: item.id,
                name: item.paper_name,
                isSelect: false,
            }));
        },
        createSchool() {
            this.$delete(this.operateSchoolParams, "id");
            this.operateDialogTitle = "创建学校";
            this.operateDialog = true;
        },
        editSchool(item) {
            this.operateDialogTitle = "编辑学校";
            this.operateSchoolParams.avatar = item.avatar;
            this.operateSchoolParams.name = item.name;
            this.operateSchoolParams.id = item.id;
            this.operateDialog = true;
        },
        confirmOperate() {
            this.$refs.elForm.validate(async (valid) => {
                let { operateSchoolParams: params } = this;
                let avatar = params.avatar && params.avatar.replace(this.host, "");
                let res = await sendOperateSchool({
                    ...params,
                    avatar,
                });
                this.$message.success(res.msg);
                this.getSchoolTableData();
                this.operateDialog = false;
            });
        },
        deleteSchool(item) {
            this.deleteId = item.id;
            this.deleteDialog = true;
        },
        async confirmDelete() {
            let res = await sendDeleteSchool({ id: this.deleteId });
            this.$message.success(res.msg);
            this.getSchoolTableData();
            this.deleteDialog = false;
        },
        toggleSelectState(item, newState) {
            item.isSelect = newState;
        },
        async assignPaper(item) {
            this.assignParams.school_id = item.id;
            const res = await sendGetAlreadyAssignPaper({ school_id: item.id });
            this.practicalTrainOptions.forEach((item) => this.$set(item, "isSelect", res.data.includes(item.id)));
            this.assignDialog = true;
        },
        async confirmAssign() {
            const list = this.practicalTrainOptions.filter((item) => item.isSelect).map((item) => item.id);
            this.assignParams.paper_id = list.join("#") + "";
            const res = await sendSchoolAssignPaper(this.assignParams);
            this.$message.success(res.msg);
            this.assignDialog = false;
            this.clearAssignParams();
        },
        clearAvatar() {
            this.operateSchoolParams.avatar = "";
        },
        clearFormData() {
            this.operateSchoolParams.name = "";
            this.operateSchoolParams.id = "";
            this.clearAvatar();
        },
        clearAssignParams() {
            this.practicalTrainOptions.forEach((item) => (item.isSelect = false));
            this.assignParams.school_id = "";
            this.assignParams.paper_id = "";
        },
        uploadSuccess(res, file, fileList) {
            this.operateSchoolParams.avatar = `${this.host}${res.data.src}`;
            this.$message.success(res.msg);
        },
        uploadErr(err, file, fileList) {
            this.$message.error("上传失败, 请重新上传");
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.index {
    box-sizing: border-box;
    height: 100%;
    padding: 20px 20px 0;
    .assign-content {
        position: relative;
        box-sizing: border-box;
        height: 279px;
        border: 1px solid #dddfeb;
        .paper-list {
            padding: 0 40px 0 10px;
            .empty {
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%);
                color: #999;
            }
            .paper-item {
                display: flex;
                align-items: center;
                padding: 10px 0;
                cursor: pointer;
                &:hover .label {
                    color: #2821fc;
                }
                .label {
                    box-sizing: border-box;
                    margin-left: 10px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    color: #1b162a;
                    overflow: hidden;
                    transition: all 0.3s;
                }
            }
        }
    }
}
.assign-select {
    #label {
        color: #2821fc;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
